<template>
    <start-layout>
        <div :class="{ 'popup-visible' : this.user.forgot_error }">
            <div class="popup-wrap">
                <div class="popup successfully-popup" :class="{ 'active' : this.user.forgot_error }">
                    <form action="#" class="popup__form">
                        <div class="popup__header">
                            <div class="popup__title">
                                <span class="title-text"></span>
                            </div>
                            <div class="popup-remove" @click="popupRemove">
                                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                                        fill="#333333"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                        fill="#333333"/>
                                </svg>
                            </div>
                        </div><!-- //popup__header -->
                        <div class="popup__content">
                            <div class="popup__section">
                                <div class="wysiwyg">
                                    <p>
                                        {{ this.user.forgot_error_message }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="popup__footer">
                            <div class="btn-wrap btn-wrap_secondary content-right single-btn-right">
                                <div class="form-field"><div class="form-field__content"><button @click.prevent="popupRemove" class="btn btn_primary">Ok</button></div></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        <form class="main__form" v-on:submit.prevent="forgotPass">
            <div class="section section_mobile-secondary">
                <div class="section__header">
                    <div class="section__wysiwyg wysiwyg text-center">
                        <h1>Forgot Password</h1>
                    </div>
                </div>
                <div class="section__content">
                    <div class="account">
                        <div class="account__password">
                            <div class="row content-center">
                                <div class="col col_7 col_mob-12">
                                    <div class="form-field">
                                        <div class="form-field__header">
                                            <label class="form-field__title" for="account-email">Email</label>
                                        </div>
                                        <div class="form-field__content">
                                            <input 
                                            v-model="email" 
                                            :required="true" 
                                            id="account-email" class="form-field__input" type="email"
                                                                   placeholder="Please enter your email" >
                                        </div>
                                    </div><!-- //form-field -->

                                </div>
                                <div class="col col_7 col_mob-12">
                                    <div class="form-field">
                                        <div class="form-field__content">
                                            <button 
                                            type="submit" 
                                            class="btn btn_primary w-100">
                                            Email me reset instructions
                                            </button>
                                        </div>
                                    </div><!-- //form-field -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section__footer footer">
                    <div class="footer__wysiwyg wysiwyg text-center">
                        <p>
                            <strong><router-link :to="{name: 'cover'}" >Never mind, go back</router-link></strong>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </start-layout>
</template>

<script>
    import StartLayout from "../layouts/Start.vue"
    import store from '../store'

    export default {
        name: "ForgotPassword",
        components: {
            StartLayout,
        },
        data() {
            return {
                email: '',
            }
        },
        methods: {
            forgotPass() {
                store.dispatch('ACCOUNT_FORGOT_PASSWORD', this.email);
            },
            popupRemove: function() {
                store.dispatch('SET_FORGOT_ERROR', false);
            }
        },
        computed: {
            user: () => store.state.user
        }
    }
</script>
